import { Badge, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';

type MileageBadgeProps = {
  className?: string;
};

const MILEAGE = 102_000;

const MileageBadge = ({ className }: MileageBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Badge size="s" className={className} variant="black">
      <Stack crossAxisAlign="center" gap={0}>
        <Text variant="2xs" textColor="light">
          {t('general.lastKnownMileageLabel')}
        </Text>
        <Text variant="xs+" textColor="light">
          {MILEAGE} km
        </Text>
      </Stack>
    </Badge>
  );
};

export { MileageBadge };
