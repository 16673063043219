import { useTranslation } from 'next-i18next';
import { Container, Text, Heading, Stack } from '@carvertical/ui';
import cx from 'classnames';
import { IdentifierForm, type IdentifierFormProps } from 'components/common/IdentifierForm';
import { TrustBanner } from '../TrustBanner';
import styles from './CallToActionSection.module.scss';

type CallToActionSectionProps = {
  illustration?: React.ReactNode;
  className?: string;
  layout?: 'center' | 'right';
  containerClassName?: string;
  identifierFormProps?: Pick<IdentifierFormProps, 'buttonLabel' | 'onSubmit'>;
  title?: string;
  titleOnly?: boolean;
};

const CallToActionSection = ({
  className,
  containerClassName,
  identifierFormProps,
  illustration,
  layout = 'center',
  title,
  titleOnly,
}: CallToActionSectionProps) => {
  const { t } = useTranslation('home');
  const centerAligned = layout === 'center';
  const rightAligned = layout === 'right';

  return (
    <section className={cx(styles.root, className)}>
      <Container
        className={cx(
          centerAligned && styles.container,
          rightAligned && styles.columns,
          containerClassName,
        )}
      >
        {illustration}

        <Stack
          gap={{ mobileUp: 3, tabletPortraitUp: 4 }}
          crossAxisAlign="center"
          mainAxisAlign="center"
          className={cx(styles.content, rightAligned && styles.sidePadding)}
        >
          <Stack crossAxisAlign="center" gap={{ mobileUp: 1, tabletPortraitUp: 2 }}>
            <Heading variant="l" as="h2" textColor="light" align="center">
              {title || t('landing.title')}
            </Heading>

            {!titleOnly && (
              <Text variant="l" textColor="light" align="center" className={styles.subtitle}>
                {t('landing.ctaSubtitle')}
              </Text>
            )}
          </Stack>
          <IdentifierForm surroundingBackground="dark" {...identifierFormProps} />
          <TrustBanner color="white" />
        </Stack>
      </Container>
    </section>
  );
};

export { CallToActionSection };
