import { Badge, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useRouteData } from 'context/RouteDataProvider';
import { format } from 'utils/money';
import styles from './SevereDamageBadge.module.scss';

type SevereDamageBadgeProps = {
  className?: string;
};

const DAMAGE_COST = 12_000;

const SevereDamageBadge = ({ className }: SevereDamageBadgeProps) => {
  const { market } = useRouteData();
  const { t } = useTranslation();

  return (
    <Stack className={cx(styles.root, className)}>
      <Badge size="s" variant="yellow" emphasized>
        <Text variant="xs+">{t('general.damageLabel')}</Text>
      </Badge>
      <div>
        <Text variant="2xs">{t('general.damageAmountLabel')}</Text>
        <Text variant="m+">{format(DAMAGE_COST, market.currency)}</Text>
      </div>
    </Stack>
  );
};

export { SevereDamageBadge };
