import { useTranslation } from 'next-i18next';
import { Badge, Stack, Text } from '@carvertical/ui';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { SevereDamageBadge } from '../common/badges/SevereDamageBadge';
import { MileageBadge } from '../common/badges/MileageBadge';
import { RollbackBadge } from '../common/badges/RollbackBadge';
import desktopImageUrl from './assets/hero-desktop.png';
import mobileImageUrl from './assets/hero-mobile.png';
import styles from './Illustration.module.scss';

const VIN = '1VWBN7A35CC••••••';

const Illustration = () => {
  const { t } = useTranslation(['precheck', 'common']);

  return (
    <figure className={styles.root}>
      <ArtDirectedImage
        src={mobileImageUrl}
        sizes="100vw"
        height={370}
        width={350}
        className={styles.image}
        alt=""
        quality={100}
        // @ts-expect-error TS(2739)
        srcSet={{
          forDesktopUp: {
            src: desktopImageUrl,
            height: 552,
            width: 705,
            sizes: '50vw',
          },
        }}
      />
      <Stack as="figcaption" gap={0} crossAxisAlign="center" className={styles.heading}>
        <Text variant="s+">{t('getReportSection.title')}</Text>
        <Badge size="s" variant="grey">
          <span>{`${t('common:general.vinShortLabel')}: ${VIN}`}</span>
        </Badge>
      </Stack>
      <MileageBadge className={styles.mileageBadge} />
      <RollbackBadge className={styles.rollbackBadge} />
      <SevereDamageBadge className={styles.damageBadge} />
    </figure>
  );
};

export { Illustration };
