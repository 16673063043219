import ArtDirectedImage from 'components/common/ArtDirectedImage';
import bgMobileUrl from './assets/bg-mobile.jpg';
import bgDesktopUrl from './assets/bg-desktop.jpg';
import styles from './CtaIllustration.module.scss';

const CtaIllustration = () => (
  <div className={styles.root}>
    <ArtDirectedImage
      src={bgMobileUrl}
      width={390}
      height={244}
      alt=""
      // @ts-expect-error TS(2739)
      srcSet={{
        forDesktopUp: {
          src: bgDesktopUrl,
          width: 608,
          height: 586,
        },
      }}
      quality={100}
      className={styles.image}
    />
  </div>
);

export { CtaIllustration };
