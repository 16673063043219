import { useTranslation } from 'next-i18next';
import { Badge } from '@carvertical/ui';

type RollbackBadgeProps = {
  className?: string;
};

const RollbackBadge = ({ className }: RollbackBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Badge size="s" className={className} variant="yellow" emphasized icon="warning">
      {t('features.mileage.title')}
    </Badge>
  );
};

export { RollbackBadge };
